<template>
  <section class="home">
    <!-- <Loader :loading="loading" /> -->
    <div class="home__surface" v-webp:bg="require('../assets/images/home.jpg')">
      <h1 class="text-title home__surface-title">{{ text.title }}</h1>
      <p class="text-description home__surface-sub-title">
        {{ text.subtext }}
      </p>
      <!-- <div class="home__surface__footer">
        <div class="home__surface__footer--left">
          <div class="home__surface__footer-line"></div>
          <BaseButton
            class="button--white button--ellipse button--lg"
            tabindex="1"
            text="Outside"
            @click="openExteriorsToure"
          />
        </div>
        <div class="home__surface__footer--right">
          <BaseButton
            class="button--white button--ellipse button--lg"
            tabindex="2"
            text="Inside"
            @click="openInteriorToure"
          />
          <div class="home__surface__footer-line"></div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  name: "Home",
  components: {
    Loader,
    BaseButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "GH - Homepage",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  async created() {
    await this.apiGetTextData("/");
  },
  computed: {
    ...mapGetters("home", { text: "getData" }),
  },
  methods: {
    ...mapActions("home", ["apiGetTextData"]),

    openInteriorToure() {
      this.$router.push({ path: "/interiors/1" });
    },
    openExteriorsToure() {
      this.$router.push({ path: "/exteriors/1" });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  height: 100vh;
  width: 100vw;

  &__surface {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($color: $black, $alpha: 0.2);
    background-image: url("../assets/images/home.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 52vh 30px 0;
    transition: all 1s ease 0s;
    @media (max-width: $sm) {
      padding: 48vh 30px 0;
    }
    @media (max-width: $xs) {
      padding: 52vh 30px 0;
    }
    &--hidden {
      animation: surfaceHidden 0.5s ease 0s forwards;
    }
    &-title {
      font-size: 10.6rem;
      font-weight: 600;
      line-height: 1.52;
      letter-spacing: -1.27px;
      text-align: center;
      @media (max-width: $xxs) {
        font-size: 6rem;
        line-height: 1.3;
        letter-spacing: -0.73px;
      }
      @media (max-width: $xxxs) {
        font-size: 4.2rem;
      }
    }
    &-sub-title {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.31;
      letter-spacing: 4.03px;
      text-align: center;
      text-transform: uppercase;
      @media (max-width: $xxs) {
        font-size: 1.9rem;
        letter-spacing: 3.48px;
      }
      @media (max-width: $xxxs) {
        font-size: 1.6rem;
      }
    }
    &__footer {
      position: absolute;
      bottom: 6.8rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .button {
        min-width: 18rem;
        width: max-content;
        @media (max-width: $xxs) {
          min-width: 14.8rem;
        }
        @media (max-width: $xxxs) {
          min-width: 12rem;
        }
      }
      &--left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        margin-right: 10px;
      }
      &--right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        margin-left: 10px;
      }
      &-line {
        position: relative;
        height: 1px;
        min-width: 2.7rem;
        background-color: $white;
        flex-grow: 1;
        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: rgba($color: $black, $alpha: 0.2);
        }
      }
    }
  }
}
@keyframes surfaceHidden {
  from {
    opacity: 1;
  }
  99% {
    z-index: auto;
  }
  to {
    opacity: 0;
    z-index: -1;
  }
}
</style>

